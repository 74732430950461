import { getTransactionType } from '@commons/helpers/transaction-type';
import axios from 'axios';
import FileDownload from 'js-file-download';
import qs from 'qs';
import { formatDateSelectors } from '../helpers/date';


// Mutation types
const SET_RISK_TRANSACTIONS = 'risk/SET_RISK_TRANSACTIONS';
const SET_RULES = 'risk/SET_RULES';
const SET_ACCOUNTS_RULES = 'risk/SET_ACCOUNTS_RULES';
const UPDATE_ACCOUNT_RULE_DATA = 'risk/UPDATE_ACCOUNT_RULE_DATA';
const SET_METRICS = 'risk/SET_METRICS';
const SET_PROCESS_RULES = 'risk/SET_PROCESS_RULES';
const SET_METRICS_BY_RULE = 'metrics/SET_METRICS_BY_RULE';
const CLEAR_METRICS_BY_RULE = 'metrics/CLEAR_METRICS_BY_RULE';
const SET_TRANSACTION_METRICS = 'metrics/SET_TRANSACTION_METRICS';
const CLEAR_TRANSACTION_METRICS = 'metrics/CLEAR_TRANSACTION_METRICS';
const SET_CC_TOKEN_METRICS = 'metrics/SET_CC_TOKEN_METRICS';
const CLEAR_CC_TOKEN_METRICS = 'metrics/CLEAR_CC_TOKEN_METRICS';


const ENDPOINTS = {
  GET_TRANSACTIONS: 'risk/transactions/groupid',
  RULES: 'risk/rules',
  ACCOUNTS_RULES: 'risk/accounts-rules',
  SINGLE_RULE: 'risk/rules/:ruleId',
  RULE_DESCRIPTION: 'risk/rules/:ruleId/description',
  LOAD_METRICS: 'metrics/load',
  RUN_EVENTS: '/events/run-now',
  PROCESS_RULES: '/sync/post-processing/transactions',
  METRICS_BY_RULE: 'metrics/rule/:ruleId',
  TRANSACTION_METRICS: 'metrics/ccTxMetrics',
  CC_TOKEN_METRICS: 'metrics/ccTokenMetrics'
};

function initialState() {
  return {
    risksTransactions: [],
    rules: {},
    accountsRules: {},
    metrics: {},
    processRulesResponse: {},
    metricsByRule: {}
  };
}
// initial state
const state = initialState();

// actions
const actions = {
  async GET_RISK_TRANSACTIONS({ commit }, data) {
    const params = formatDateSelectors(data);
    params.operation = params.operation.map(i => getTransactionType(i));
    try {
      const response = await axios.get(ENDPOINTS.GET_TRANSACTIONS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false })
      });
      commit(SET_RISK_TRANSACTIONS, {
        count: response.data.count,
        data: response.data.data
      });
    } catch {
      throw new Error('GET_RISK_TRANSACTIONS_ERROR_MESSAGE');
    }
  },
  CLEAR_RISK_TRANSACTIONS({ commit }) {
    commit(SET_RISK_TRANSACTIONS, []);
  },
  async ADD_RULE(context, data) {
    try {
      const response = await axios.post(ENDPOINTS.RULES, data);
      return response.data;
    } catch {
      throw new Error('ADD_RULE_ERROR_MESSAGE');
    }
  },
  async EDIT_RULE(context, data) {
    try {
      const response = await axios.put(ENDPOINTS.RULES, data);
      return response.data;
    } catch {
      throw new Error('EDIT_RULE');
    }
  },
  async DELETE_RULE(context, ruleId) {
    try {
      await axios.delete(ENDPOINTS.SINGLE_RULE.replace(':ruleId', ruleId));
    } catch {
      throw new Error('DELETE_RULE');
    }
  },
  async GET_RULES({ commit }, data) {
    try {
      const response = await axios.get(ENDPOINTS.RULES, { params: data });
      commit(SET_RULES, {
        count: response.data.count,
        data: response.data.data
      });
    } catch {
      throw new Error('Error while getting the rules. Try again later.');
    }
  },
  async GET_ACCOUNTS_RULES({ commit }, data) {
    try {
      const response = await axios.get(ENDPOINTS.ACCOUNTS_RULES, {
        params: data,
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: false }),
        cancelPreviousRequests: true
      });
      commit(SET_ACCOUNTS_RULES, {
        count: response.data.count,
        data: response.data.data
      });
      return response
    } catch {
      throw new Error('Error while getting the rules. Try again later.');
    }
  },
  async DOWNLOAD_ACCOUNTS_RULES_TABLE(context, data) {
    try {
      const params = formatDateSelectors(data);
      const response = await axios.get(ENDPOINTS.ACCOUNTS_RULES, {
        params: { ...params, exportFormat: 'csv' },
        paramsSerializer: params =>
          qs.stringify(params, { indices: false, allowDots: false }),
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/csv'
        },
        cancelPreviousRequests: true
      });
      FileDownload(response.data, 'accounts.csv');
    } catch (error) {

    }
  },
  async GET_SINGLE_RULE({ commit }, ruleId) {
    try {
      const response = await axios.get(ENDPOINTS.RULES, { params: { ruleId } });
      return response.data;
    } catch {
      throw new Error('Error while getting the rule. Try again later.');
    }
  },
  CLEAR_RULES({ commit }) {
    commit(SET_RULES, {});
  },
  CLEAR_ACCOUNTS_RULES({ commit }) {
    commit(SET_ACCOUNTS_RULES, {});
  },
  ADD_RULE_TO_LIST({ commit, state }, rule) {
    const rules = JSON.parse(JSON.stringify(state.rules));
    const elementIndex = rules.data.findIndex(c => c._id === rule._id);
    const ruleToAdd = { ...rule };
    if (elementIndex === -1) {
      rules.data.unshift(ruleToAdd);
      rules.count++;
    } else {
      rules.data[elementIndex] = ruleToAdd;
    }
    commit(SET_RULES, rules);
  },
  DELETE_RULE_FROM_LIST({ commit, state }, ruleId) {
    const rules = JSON.parse(JSON.stringify(state.rules));
    const elementIndex = rules.data.findIndex(c => c._id === ruleId);
    if (elementIndex !== -1) {
      rules.data.splice(elementIndex, 1);
      rules.count--;
    }
    commit(SET_RULES, rules);
  },
  async LOAD_METRICS({ commit }, data) {
    try {
      const response = await axios.get(ENDPOINTS.LOAD_METRICS, { params: { period: data.period } });
      commit(SET_METRICS, response.data);
      return response.data;
    } catch {
      throw new Error('Error while trying to get the metrics');
    }
  },
  async RUN_EVENTS({ commit }, name) {

    try {
      const response = await axios({
        method: 'POST',
        url: ENDPOINTS.RUN_EVENTS,
        data: { name }
      });

      commit(SET_METRICS, response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw new Error('Error while trying to get the metrics');
    }
  },
  CLEAR_METRICS({ commit }) {
    commit(SET_METRICS, {});
  },
  async PROCESS_RULES({ commit }) {
    try {
      const response = await axios.post(ENDPOINTS.PROCESS_RULES);
      commit(SET_PROCESS_RULES, response.data);
      return response.data;
    } catch {
      throw new Error('Error while processing the rules');
    }
  },
  CLEAR_PROCESS_RULES({ commit }) {
    commit(SET_PROCESS_RULES, {});
  },
  async FETCH_METRICS_BY_RULE({ commit }, { ruleId, subMerchantId, alertId, allMetrics }) {
    try {
      const response = await axios.post(ENDPOINTS.METRICS_BY_RULE.replace(':ruleId', ruleId), {
        subMerchantId,
        alertId,
        allMetrics
      });
      commit(SET_METRICS_BY_RULE, { ruleId, data: response.data });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async GET_RULE_DESCRIPTION({ commit }, ruleId) {
    try {
      return await axios.get(ENDPOINTS.RULE_DESCRIPTION.replace(':ruleId', ruleId));
    } catch (error) {
      throw error;
    }
  },
  CLEAR_METRICS_BY_RULE({ commit }) {
    commit(CLEAR_METRICS_BY_RULE);
  },
  async GET_TRANSACTION_METRICS({ commit }, data) {
    try {
      if (data.numberInputsPrefixes) delete data.numberInputsPrefixes;
      const params = formatDateSelectors(data);
      const response = await axios.get(ENDPOINTS.TRANSACTION_METRICS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false }),
        cancelPreviousRequests: true
      });
      commit(SET_TRANSACTION_METRICS, response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  CLEAR_TRANSACTION_METRICS({ commit }) {
    commit(CLEAR_TRANSACTION_METRICS);
  },
  async DOWNLOAD_TRANSACTION_METRICS_TABLE({ commit }, data) {
    const params = formatDateSelectors(data);
    console.log('params', params, data);
    const response = await axios.get(ENDPOINTS.TRANSACTION_METRICS, {
      params: { ...params, exportFormat: 'csv' },
      paramsSerializer: params =>
        qs.stringify(params, { indices: false, allowDots: false }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/csv'
      },
      cancelPreviousRequests: true
    });
    FileDownload(response.data, 'txMetrics.csv');
  },
  async GET_CC_TOKEN_METRICS({ commit }, data) {

    try {
      if (data.numberInputsPrefixes) delete data.numberInputsPrefixes;
      const params = formatDateSelectors(data);
      const response = await axios.get(ENDPOINTS.CC_TOKEN_METRICS, {
        params,
        paramsSerializer: params => qs.stringify(params, { indices: false }),
        cancelPreviousRequests: true
      });
      commit(SET_CC_TOKEN_METRICS, response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  CLEAR_CC_TOKEN_METRICS({ commit }) {
    commit(CLEAR_CC_TOKEN_METRICS);
  },
  async DOWNLOAD_CC_TOKEN_METRICS_TABLE({ commit }, data) {
    const params = formatDateSelectors(data);

    const response = await axios.get(ENDPOINTS.CC_TOKEN_METRICS, {
      params: { ...params, exportFormat: 'csv' },
      paramsSerializer: params =>
        qs.stringify(params, { indices: false, allowDots: false }),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/csv'
      },
      cancelPreviousRequests: true
    });
    FileDownload(response.data, 'ccTokenMetrics.csv');
  }
};

// getters
const getters = {
  getRiskTransactions: state => state.risksTransactions,
  getRules: state => state.rules,
  getAccountsRules: state => state.accountsRules,
  getMetrics: state => state.metrics,
  getProcessRulesResponse: state => state.processRulesResponse,
  getMetricsByRule: state => ruleId => state.metricsByRule[ruleId] || [],
  getRuleMetrics: state => state.metricsByRule,
  getTransactionMetrics: state => state.transactionMetrics,
  getCCTokenMetrics: state => state.cctokenMetrics
};

// mutations
const mutations = {
  [SET_RISK_TRANSACTIONS](state, data) {
    state.risksTransactions = data;
  },
  [SET_RULES](state, data) {
    state.rules = data;
  },
  [SET_ACCOUNTS_RULES](state, data) {
    state.accountsRules = data;
  },
  [UPDATE_ACCOUNT_RULE_DATA](state, { subMerchantId, metrics, ruleId }) {
    const rowIndex = state.accountsRules.data.findIndex(row => row.subMerchantId === subMerchantId);
    const row = state.accountsRules.data[rowIndex];
    const ruleIndex = row.rules.findIndex(rule => rule.ruleId === ruleId);
    Object.assign(row.rules[ruleIndex].params, metrics);
  },
  [SET_METRICS](state, data) {
    state.metrics = data;
  },
  [SET_PROCESS_RULES](state, data) {
    state.processRulesResponse = data;
  },
  [SET_METRICS_BY_RULE](state, { ruleId, data }) {
    state.metricsByRule = {
      ...state.metricsByRule,
      [ruleId]: JSON.parse(JSON.stringify(data))
    };
  },
  [CLEAR_METRICS_BY_RULE](state) {
    state.metricsByRule = {};
  },
  [SET_TRANSACTION_METRICS](state, data) {
    state.transactionMetrics = data;
  },
  [CLEAR_TRANSACTION_METRICS](state) {
    state.transactionMetrics = [];
  },
  [SET_CC_TOKEN_METRICS](state, data) {
    state.cctokenMetrics = data;
  },
  [CLEAR_CC_TOKEN_METRICS](state) {
    state.cctokenMetrics = [];
  }
};

export default {
  state: { ...state },
  actions,
  getters,
  mutations
};